<template>
  <div class="board">
    <canvas
      id='carte'
      ref='carte'
      :width="size.w"
      :height="size.h"
      max-width="100%"
      tabindex='0'
      style="border:1px solid #000000;"
    ></canvas>
  </div>
  <div id="texteRemplacement" v-if="petit">
    <p v-for="p in texte.split('\n')" v-bind:key="p">
      {{ p }}
    </p>
  </div>
</template>

<script>

import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  nextTick,
  toRefs,
  watch,
} from 'vue';
import axios from 'axios';

export default defineComponent({
  name: 'Visionneuse',
  props: ['texteEnvoye', 'taille'],
  setup(props) {
    const myCanvas = ref(null);
    const carte = ref(null);
    const { texteEnvoye, taille } = toRefs(props);
    const texte = ref('');
    const imgFond = ref('');
    const imgEtoile = ref('');
    const imgLogo = ref('');
    const imgLogoTexte = ref('');

    // le plus on est à gauche ou à droite, le plus la base est grande
    function genererSapin() {
      const couleursSapins = [
        ['06539c'],
        ['1596d5'],
        ['5bbeef'],
        [['e1fffa', 0], ['06539c', 0.21], ['232055', 0.53]],
        [['06539c', 0], ['06539c', 0.66]],
        [['5bbeef', 0], ['06539c', 1]],
      ];
      const X = Math.random();
      let Y;
      if (X > 0.5) {
        Y = ((1 - 0.4 * X) / 2) + Math.random() * (0.7 - 0.4 * X);
      } else {
        Y = ((0.6 + 0.4 * X) / 2) + Math.random() * (0.3 + 0.4 * X);
      }
      return ({
        sommet: {
          x: X,
          y: Y,
        },
        base: 0.1 + Math.random() / 5,
        couleur: couleursSapins[Math.floor(Math.random() * 6)],
      });
    }
    const sapins = ref(new Array(50).fill(null).map(() => (genererSapin())));

    async function getImage(chemin) {
      const image = await axios.get(`${process.env.VUE_APP_PUBLICPATH}img/${chemin}`, { responseType: 'arraybuffer' })
        .then((res) => `data:${res.headers['content-type']};base64,${btoa(String.fromCharCode(...new Uint8Array(res.data)))}`)
        .catch((e) => {
          console.log(e);
        });
      return image;
    }

    let fpsInterval;
    let startTime;
    let now;
    let then;
    let elapsed;

    function getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    }

    function genererEtoile() {
      return ({
        taille: getRandomInt(10) + 10,
        position: {
          x: Math.random(),
          y: Math.random() / 4,
        },
        grossissement: {
          valeur: getRandomInt(5) - 3,
          sens: 1,
        },
      });
    }

    const etoiles = ref(new Array(20).fill(null).map(() => (genererEtoile())));

    const rapport = ref(0);
    const petit = ref((window.innerWidth < 750));

    const size = reactive({
      w: window.innerWidth * taille.value,
      h: window.innerHeight * taille.value,
    });

    const tailleFond = reactive({
      w: 0,
      h: 0,
    });

    /* eslint-disable */
    function LightenColor(color, percent) {
      let num = parseInt(color,16),
      amt = Math.round(2.55 * percent),
      R = (num >> 16) + amt,
      B = (num >> 8 & 0x00FF) + amt,
      G = (num & 0x0000FF) + amt;
      return (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (B<255?B<1?0:B:255)*0x100 + (G<255?G<1?0:G:255)).toString(16).slice(1);
    };
    /* eslint-enable */

    function dessinerSapin(sommet, base, couleur) {
      /* eslint-disable */
      myCanvas.value.beginPath();
      myCanvas.value.moveTo(sommet.x * size.w, sommet.y * size.h);
      myCanvas.value.lineTo((sommet.x - base / 3) * size.w, (sommet.y + base / 2) * size.h);
      myCanvas.value.lineTo((sommet.x - 0.5 * (base / 3)) * size.w, (sommet.y + base / 2) * size.h);
      myCanvas.value.lineTo((sommet.x - base / 3) * size.w, (sommet.y + 1.5 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x - 0.5 * (base / 3)) * size.w, (sommet.y + 1.5 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x - base / 3) * size.w, (sommet.y + 2 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x - 0.5 * (base / 3)) * size.w, (sommet.y + 2 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x - base / 3) * size.w, (sommet.y + 2.5 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x + base / 3) * size.w, (sommet.y + 2.5 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x + 0.5 * (base / 3)) * size.w, (sommet.y + 2 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x + base / 3) * size.w, (sommet.y + 2 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x + 0.5 * (base / 3)) * size.w, (sommet.y + 1.5 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x + base / 3) * size.w, (sommet.y + 1.5 * (base / 2)) * size.h);
      myCanvas.value.lineTo((sommet.x + 0.5 * (base / 3)) * size.w, (sommet.y + base / 2) * size.h);
      myCanvas.value.lineTo((sommet.x + base / 3) * size.w, (sommet.y + base / 2) * size.h);
      /* eslint-enable */

      if (couleur.length === 1) {
        myCanvas.value.fillStyle = `#${couleur}`;
      } else {
        const gradient = myCanvas.value
          .createLinearGradient(
            sommet.x * size.w,
            sommet.y * size.h,
            sommet.x * size.w,
            (sommet.y + 2.5 * (base / 2)) * size.h,
          );
        couleur.forEach((c) => {
          gradient.addColorStop(c[1], `#${c[0]}`);
        });
        myCanvas.value.fillStyle = gradient;
      }
      myCanvas.value.fill();
    }

    function actualiserForet() {
      sapins.value.forEach((sapin, i) => {
        // si le sapin est en dehors du cadre, on le supprime
        if (((sapin.sommet.x > 1)
          && (sapin.sommet.x - sapin.base / 3 > 1))
          || ((sapin.sommet.x < 0)
          && (sapin.sommet.x + sapin.base / 3 < 0))) {
          sapins.value.splice(i, 1);
        }
      });
    }

    function dessinerForet() {
      sapins.value.forEach((sapin, i) => {
        // on actualise les sapins
        sapins.value[i].sommet.x += (
          (sapins.value[i].sommet.x > 0.5 ? 1 : -1) * (
            ((now - startTime) / 600) ** 1.5)) / tailleFond.w;
        sapins.value[i].sommet.y += ((sapins.value[i].sommet.x > 0.5 ? 0.5 : -1) * (
          ((now - startTime) / 1600) ** 1.5)) / tailleFond.h;
        sapins.value[i].base += (((now - startTime) / 1500) ** 1.5) / tailleFond.w;
        dessinerSapin(sapin.sommet, sapin.base, sapin.couleur);
      });
    }

    function drawText() {
      const fontSize = 0.06 * size.w - 10;
      myCanvas.value.font = `${fontSize}px Alex Brush`;
      myCanvas.value.textAlign = 'center';
      myCanvas.value.fillStyle = 'black';
      myCanvas.value.strokeStyle = 'gray';
      myCanvas.value.lineWidth = 0.006 * size.w - 10;
      const x = size.w / 2;
      const y = (size.h * 7) / 8;
      const lineHeight = fontSize;
      const lines = texte.value.split('\n');
      for (let i = 0; i < lines.length; i += 1) {
        myCanvas.value.fillText(
          lines[lines.length - i - 1],
          x,
          y + ((lines.length - 1) * lineHeight) / 2 - (i * lineHeight),
        );
      }
    }

    function initCarte() {
      requestAnimationFrame(initCarte);
      now = Date.now();
      elapsed = now - then;

      if (elapsed > fpsInterval) {
        then = now - (elapsed % fpsInterval);
        petit.value = window.innerWidth < 750;
        const etoile = new Image();
        etoile.src = imgEtoile.value;
        const logo = new Image();
        logo.src = imgLogo.value;
        const logoTexte = new Image();
        logoTexte.src = imgLogoTexte.value;
        const background = new Image();
        background.src = imgFond.value;
        background.onload = () => {
          rapport.value = background.naturalWidth / background.naturalHeight;
          tailleFond.w = background.naturalWidth;
          tailleFond.h = background.naturalHeight;
          size.w = window.innerWidth * taille.value;
          size.h = size.w / rapport.value;
          if (size.w > window.innerWidth) {
            size.h = size.w / rapport.value;
          } else if (size.h > window.innerHeight) {
            size.h = window.innerHeight * taille.value;
            size.w = size.h * rapport.value;
          }
          const fondRadial = myCanvas.value.createRadialGradient(
            size.w / 2,
            size.h / 2,
            1,
            size.w / 2,
            size.h / 2,
            size.w / 2,
          );
          fondRadial.addColorStop(0, '#e1fffa');
          fondRadial.addColorStop(0.62, '#06539c');
          fondRadial.addColorStop(0.94, '#232055');
          nextTick(() => {
            try {
              myCanvas.value.fillStyle = fondRadial;
              myCanvas.value.fillRect(0, 0, size.w, size.h);
              // on dessine les étoiles
              etoiles.value.forEach((e) => {
                if (e.grossissement.valeur > 3 && e.grossissement.sens === 1) {
                  e.grossissement.sens = -1;
                }
                if (e.grossissement.valeur < -3 && e.grossissement.sens === -1) {
                  e.grossissement.sens = 1;
                }
                e.grossissement.valeur += e.grossissement.sens;
                myCanvas.value.drawImage(etoile,
                  (e.position.x * size.w) - e.grossissement.valeur / 2,
                  (e.position.y * size.h) - e.grossissement.valeur / 2,
                  (e.taille + e.grossissement.valeur) * (size.w / background.naturalWidth),
                  (e.taille + e.grossissement.valeur) * (size.h / background.naturalHeight));
              });
              myCanvas.value.drawImage(background, 0, 0, size.w, size.h);
              const rapportLogo = logo.naturalWidth / logo.naturalHeight;
              myCanvas.value.drawImage(logo,
                size.w / 2 - ((rapportLogo * size.h) / 6),
                size.h / 3,
                (rapportLogo * size.h) / 3,
                size.h / 3);
              const rapportLogoTexte = logoTexte.naturalWidth / logoTexte.naturalHeight;
              myCanvas.value.drawImage(logoTexte,
                0.01 * size.w,
                (size.h - ((size.w / 4) / rapportLogoTexte)) * 0.99,
                size.w / 4,
                (size.w / 4) / rapportLogoTexte
              );
              if (!petit.value) {
                drawText();
              }
              dessinerForet();
              actualiserForet();
            } catch (e) {
              console.log(`ERREUR DE CHARGEMENT D'IMAGE: ${e}`);
            }
          });
        };
      }
    }

    function startAnimating(fps) {
      fpsInterval = 1000 / fps;
      then = Date.now();
      startTime = then;
      console.log(startTime);
      initCarte();
    }

    window.addEventListener('resize', initCarte);

    watch(texteEnvoye, (x) => {
      texte.value = x;
    });

    onMounted(async () => {
      imgFond.value = await getImage('fond_2022.png');
      imgEtoile.value = await getImage('etoile_2022.png');
      imgLogo.value = await getImage('logo_ST_bleu_sanstexte.png');
      imgLogoTexte.value = await getImage('logo_ST_bleu.png');
      const c = carte.value;
      const ctx = c.getContext('2d');
      myCanvas.value = ctx;
      startAnimating(20);
    });

    return {
      myCanvas,
      size,
      texte,
      petit,
      carte,
      tailleFond,
    };
  },
});

</script>

<style scoped>
@font-face {
  font-family: 'Alex Brush';
  src: local('Alex Brush'), url(~@/assets/fonts/AlexBrush-Regular.ttf) format('truetype');
}

#texteRemplacement {
  font-size: 20pt;
  font-family: 'Alex Brush';
}

#myCanvas {
  border: 1px solid grey;
}

</style>
